import Image from "next/image";
import ReactTooltip from "react-tooltip";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import FacebookIcon from "../../public/images/icons/fb.svg";
import TwitterIcon from "../../public/images/icons/twitter.svg";
import ShareIcon from "../../public/images/icons/share.svg";
import LinkIcon from "../../public/images/icons/link.svg";
import EmbedIcon from "../../public/images/icons/embed.svg";
import FacebookIconOrange from "../../public/images/icons/fb-orange.svg";
import TwitterIconOrange from "../../public/images/icons/twitter-orange.svg";
import ShareIconOrange from "../../public/images/icons/share-orange.svg";
import LinkIconOrange from "../../public/images/icons/link-orange.svg";
import styles from "../styles/SocialButtons.module.scss";

const loader = ({ src, width, quality }) => {
  return `${src}?w=${width}&q=${quality || 75}`;
};

const GlobalShare = ({
  url,
  title,
  fbQuote,
  tWQuoute,
  toolTipId,
  isMounted,
  containerStyle = styles.socialContainer,
  customLoader = loader,
}) => {
  const copyLink = () => {
    const el = document.createElement("input");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  return (
    <div className={`${styles.globalShareContainer} ${containerStyle}`}>
      <h3 className={styles.shareTitle}>{title}</h3>
      <div className={styles.socialIcons}>
        <FacebookShareButton url={url} quote={fbQuote}>
          <div className={styles.icon}>
            <Image
              loader={customLoader}
              src={FacebookIconOrange}
              alt="facebook"
              layout="responsive"
              width={25}
              height={25}
            />
          </div>
        </FacebookShareButton>

        <TwitterShareButton url={url} title={tWQuoute}>
          <div className={styles.icon}>
            <Image
              loader={loader}
              src={TwitterIconOrange}
              alt="twitter"
              layout="responsive"
              width={25}
              height={20}
            />
          </div>
        </TwitterShareButton>

        <div
          className={styles.icon}
          data-for={toolTipId}
          data-tip={"Copied!"}
          data-event={"click"}
        >
          <div className={styles.mobile}>
            <Image
              loader={loader}
              src={ShareIconOrange}
              alt="share"
              layout="responsive"
              width={25}
              height={25}
            />
          </div>
          <div className={styles.desktop}>
            <Image
              loader={loader}
              src={LinkIconOrange}
              alt="share"
              layout="responsive"
              width={25}
              height={25}
            />
          </div>
        </div>

        {isMounted && (
          <ReactTooltip
            id={toolTipId}
            globalEventOff={"click"}
            afterShow={copyLink}
          />
        )}
      </div>
    </div>
  );
};

const WidgetShare = ({
  url,
  fbQuote,
  tWQuoute,
  toolTipId,
  isMounted,
  containerStyle = styles.socialContainer,
  iconsWrapper = styles.socialIcons,
  iconContainerStyle = styles.icon,
  isEmbedShowed = false,
  embedUrl = "",
  customLoader = loader,
}) => {
  const copyLink = () => {
    const el = document.createElement("input");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  const copyEmbedLink = () => {
    const iframeString = `<iframe src="${embedUrl}" height="800px" width="800px">`;
    const el = document.createElement("input");
    el.value = iframeString;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  return (
    <div className={containerStyle}>
      <div className={iconsWrapper}>
        <FacebookShareButton url={url} quote={fbQuote}>
          <div className={iconContainerStyle}>
            <Image
              loader={customLoader}
              src={FacebookIcon}
              alt="facebook"
              layout="responsive"
              width={25}
              height={25}
            />
          </div>
        </FacebookShareButton>

        <TwitterShareButton url={url} title={tWQuoute}>
          <div className={iconContainerStyle}>
            <Image
              loader={loader}
              src={TwitterIcon}
              alt="twitter"
              layout="responsive"
              width={25}
              height={20}
            />
          </div>
        </TwitterShareButton>

        <div
          className={iconContainerStyle}
          data-for={toolTipId}
          data-tip={"Copied!"}
          data-event={"click"}
        >
          <div className={iconContainerStyle === styles.icon ? styles.mobile : `${styles.icon} mobile`}>
            <Image
              loader={loader}
              src={ShareIcon}
              alt="share"
              layout="responsive"
              width={25}
              height={25}
            />
          </div>
          <div className={iconContainerStyle === styles.icon ? styles.desktop : `${styles.icon} desktop`}>
            <Image
              loader={loader}
              src={LinkIcon}
              alt="link"
              layout="responsive"
              width={25}
              height={25}
            />
          </div>
        </div>

        {isMounted && (
          <ReactTooltip
            id={toolTipId}
            globalEventOff={"click"}
            afterShow={copyLink}
          />
        )}

        {isEmbedShowed && embedUrl !== "" ? <>
          <div
            className={iconContainerStyle}
            data-for={`${toolTipId}_embed`}
            data-tip={"Copied!"}
            data-event={"click"}
          >
            <Image
              loader={loader}
              src={EmbedIcon}
              alt="embed"
              layout="responsive"
              width={25}
              height={25}
            />
          </div>

          {isMounted && (
            <ReactTooltip
              id={`${toolTipId}_embed`}
              globalEventOff={"click"}
              afterShow={copyEmbedLink}
            />
          )}
        </>: ""}
        
      </div>
    </div>
  );
};

export { GlobalShare, WidgetShare };