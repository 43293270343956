import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from "chart.js";
import { Line } from "react-chartjs-2";
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import styles from "../../../styles/races/national/MapResults.module.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");

    const div = document.createElement("div");
    div.style.margin = "10px";

    tooltipEl.appendChild(div);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  tooltipEl.style.background = "#FFF";
  tooltipEl.style.opacity = 0.9;
  tooltipEl.style.boxShadow = "0px 4px 20px -5px rgba(80, 85, 139, 0.3)";
  tooltipEl.style.color = "#000";
  tooltipEl.style.border = "1px solid #FE1B5C";
  tooltipEl.style.borderRadius = "2px";
  tooltipEl.style.pointerEvents = "none";
  tooltipEl.style.position = "absolute";
  tooltipEl.style.transform = "translate(-50%, 10%)";
  tooltipEl.style.transition = "all .1s ease";
  tooltipEl.style.width = "250px";
  tooltipEl.style.zIndex = "1";

  return tooltipEl;
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: "time",
      time: {
        unit: "hour",
        displayFormats: {
          hour: "M/d/yy h aa"
        }
      },
      adapters: { 
        date: {
          locale: enUS
        }
      }
    },
    y: {
      grid: {
        color: "transparent"
      },
      ticks: {
        callback: (value) => {
          return new Intl.NumberFormat("en-US", {
            notation: "compact",
            maximumFractionDigits: 1,
          }).format(value);
        },
      },
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
      external: (context) => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        const divRoot = tooltipEl.querySelector("div");
        const dataset = tooltip.dataPoints[0].dataset;
        const dataIndex = tooltip.dataPoints[0].dataIndex;

        const labelContainer = document.createElement("div");
        labelContainer.style.display = "flex";
        labelContainer.style.alignItems = "center";
        labelContainer.style.marginBottom = "10px";

        const label = document.createElement("span");
        label.innerText = dataset.rawName;
        label.style.fontSize = "12px";
        label.style.fontWeight = "600";
        label.style.marginLeft = "10px";

        const thumbnail = document.createElement("img");
        thumbnail.src = dataset.thumbnail;
        thumbnail.alt = dataset.label;
        thumbnail.style.width = "25px";

        labelContainer.appendChild(thumbnail);
        labelContainer.appendChild(label);

        const body = document.createElement("p");
        body.innerText = `Vote Count: ${dataset.data[
          dataIndex
        ].y.toLocaleString()}`;
        body.style.fontSize = "16px";
        body.style.fontWeight = "600";
        body.style.marginBottom = "10px";

        const footer = document.createElement("p");
        footer.innerText = `As of ${dataset.timestamps[dataIndex]}`;
        footer.style.fontSize = "12px";

        const note = document.createElement("p");
        let transmissionPercentage = dataset.transmissionPercentage[dataIndex];
        transmissionPercentage = transmissionPercentage
          ? transmissionPercentage
          : dataset.transmissionPercentage[
              dataset.transmissionPercentage.length - 1
            ];
        note.innerText = transmissionPercentage
          ? `Transmission Status: ${transmissionPercentage}%`
          : "";
        note.style.fontSize = "12px";

        // Remove old children
        while (divRoot.firstChild) {
          divRoot.firstChild.remove();
        }

        divRoot.appendChild(labelContainer);
        divRoot.appendChild(body);
        divRoot.appendChild(footer);
        divRoot.appendChild(note);

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.fontFamily = "Noto Sans";
        tooltipEl.style.left = positionX + tooltip.caretX + "px";
        tooltipEl.style.top = positionY + tooltip.caretY + "px";
        tooltipEl.style.padding =
          tooltip.options.padding + "px " + tooltip.options.padding + "px";
      },
    },
    legend: {
      display: false,
    },
  },
};

const CumulativeChart = ({ dataChart, title, description }) => {
  return (
    <>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
      <Line options={options} data={dataChart} />
    </>
  );
};

export default CumulativeChart;
