/**
 *
 * @param {*} url
 * @returns {string} slug return slug of an article
 */
export function extractUrlSlug(url) {
  let splitUrl = url.split("/");

  if (splitUrl[splitUrl.length - 1] !== "") {
    return splitUrl[splitUrl.length - 1];
  } else {
    return splitUrl[splitUrl.length - 2];
  }
}

/**
 *
 * @param {date} date
 * @returns {string} x hours ago
 */
export function ConvertDateToTimeSince(date) {
  let currentDate = new Date(Date.now());
  let pubDate = new Date(date);

  let minutesPerDay = 1000 * 60;
  let timeDiff = (currentDate - pubDate) / minutesPerDay;

  if (timeDiff < 60) {
    const minutes = timeDiff > 1 ? "minutes" : "minute";
    return `${Math.ceil(timeDiff)} ${minutes} ago`;
  } else {
    if (Math.ceil(timeDiff / 60) < 8) {
      const hours = Math.ceil(timeDiff / 60);
      if (hours < 8) {
        const time = hours > 1 ? "hours" : "hour";
        return `${Math.ceil(timeDiff / 60)} ${time} ago`;
      }
    } else {
      const date = pubDate.toDateString().split(" ").slice(1);
      const time = pubDate.toLocaleTimeString();
      const zone = String(String(pubDate).split("(")[1])
        .split(")")[0]
        .split(" ")
        .map((m) => m[0])
        .join("");
      const finalZone = zone.toLowerCase() === "pst" ? "PHT" : zone;
      return `${date[0]}${date[0].toLowerCase() !== "may" ? "." : ""} ${
        date[1]
      }, ${date[2]} ${time.toLocaleString().replace(/:\d{2}\s/, " ")}`;
    }
  }
}

export const getFormattedDateTime = (timestamp) => {
  const timestampDate = new Date(timestamp);
  const hours = timestampDate.getHours();
  const minutes = timestampDate.getMinutes();
  const suffix =
    hours === 12 ? "NN" : hours === 0 ? "MN" : hours < 12 ? "AM" : "PM";
  return `${timestampDate.toLocaleDateString().replace("2022", "22")} ${
    hours % 12 === 0 ? 12 : hours % 12
  }${
    minutes > 0 ? (minutes > 9 ? `:${minutes}` : `:0${minutes}`) : ""
  }${suffix}`;
};

export const getFriendlyFormattedDateTime = (timestamp) => {
  const timestampDate = new Date(timestamp);
  const hours = timestampDate.getHours();
  const minutes = timestampDate.getMinutes();
  const suffix =
    hours === 12 ? "NN" : hours === 0 ? "MN" : hours < 12 ? "AM" : "PM";
  return `${timestampDate.toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })} ${ hours % 12 === 0 ? 12 : hours % 12}${
    minutes > 0 ? (minutes > 9 ? `:${minutes}` : `:0${minutes}`) : ""
  }${suffix}`;
};

export const addHours = (hours, date) => {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  return date.getTime();
};

export function getTransmissionPercentage(count, total) {
  if (count && total) {
    return ((count / total) * 100).toFixed(2);
  }
  return 0;
}

function getParams(val, root, parent) {
  if (root !== undefined && parent === undefined) {
    return val[root];
  } else if (root !== undefined && parent !== undefined) {
    return val[root][parent];
  } else {
    return val;
  }
}

export function filterBy(
  arr,
  params = {
    root: null,
    parent: null,
  },
  key,
  searchString
) {
  return arr.filter(
    (item) =>
      getParams(item, params.root, params.parent)[key].toLowerCase() ===
      searchString.toLowerCase()
  );
}

export function groupBy(
  arr,
  params = {
    root: null,
    parent: null,
  },
  key
) {
  return arr.reduce((rv, x) => {
    (rv[getParams(x, params.root, params.parent)[key]] =
      rv[getParams(x, params.root, params.parent)[key]] || []).push(x);
    return rv;
  }, {});
}

export function stringToOrdinalNum() {
  return {
    FIRST: "1st",
    SECOND: "2nd",
    THIRD: "3rd",
    FOURTH: "4th",
    FIFTH: "5th",
    SIXTH: "6th",
    SEVENTH: "7th",
    EIGHTH: "8th",
    NINTH: "9th",
    TENTH: "10th",
  };
}

// for embedding purposes
export function transformRaceName(raceName) {
  switch(raceName) {
    case 'PRESIDENT PHILIPPINES': {
      return 'president';
    }
    case 'VICE PRESIDENT PHILIPPINES': {
      return 'vice-president';
    }
    case 'SENATOR PHILIPPINES': {
      return 'senator';
    }
    default: {
      return 'president';
    }
  }
}
