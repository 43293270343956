import Image from "next/image";
import Link from "next/link";
import useSWRImmutable from "swr/immutable";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { WidgetShare } from "./SocialButtons";
import { ProgressBarWidget } from "./ProgressBar";
import PinIcon from "../../public/images/icons/pin.svg";
import RewindIcon from "../../public/images/icons/rewind.svg";
import BackArrow from "../../public/images/icons/left-arrow.svg";
import RightArrow from "../../public/images/icons/angle-right.svg";
import GenericMaleThumbnail from "../../public/images/thumbs-generic-male-1.png";
import GenericFemaleThumbnail from "../../public/images/thumbs-generic-female-1.png";
import CumulativeChart from "./races/national/CumulativeChart";
import ChartLegend from "./races/national/ChartLegend";
import styles from "../styles/RaceWidget.module.scss";
import {
  getFormattedDateTime,
  getTransmissionPercentage,
  getFriendlyFormattedDateTime,
  addHours,
  transformRaceName,
} from "../helpers/util";

const cfUrl = process.env.API_CF_URL;
const basePath = process.env.SITE_URL;
const loader = ({ src, width, quality }) => {
  return `${src}?w=${width}&q=${quality || 75}`;
};

const RaceWidget = ({
  candidatesList,
  regionList,
  contestName,
  raceName,
  subText,
  description,
  moreAboutText,
  readMoreLink,
  url,
  quote,
  urlPath,
  display,
  marginTop,
  precinctsLocal,
  view = "results",
  locSlug = "philippines",
  withCollapse = true,
  withResultsFooter = true,
  resetContainerMargin = false,
  isEmbed = false,
  isProvince = false,
}) => {
  const TOTAL_PRECINCTS_PH = regionList
    ? regionList
        .map((region) => (region || {}).precincts_count)
        .reduce((a, b) => a + b, 0)
    : null;
  const TOTAL_PRECINCTS_OAV = (
    regionList.find((region) => region.slug === "oav") || {}
  ).precincts_count;
  const TIMELAPSE_INTERVAL_CHART = 60 * 60 * 1000; // 1 hour
  const ELECTION_DAY_TRANSMISSION_START = 1652094000000;

  const _raceType = raceName;
  const _locationSlug =
    view === "overseas-absentee-voting-results" ? "oav" : locSlug;
  const _PRECINCTS =
    view === "overseas-absentee-voting-results"
      ? TOTAL_PRECINCTS_OAV
      : view === "local-results"
      ? precinctsLocal
      : TOTAL_PRECINCTS_PH;
  const canvasReportCurrent =
    candidatesList[0]?.contest?.canvass_report_certificates_current;
  const canvasReportTotal =
    candidatesList[0]?.contest?.canvass_report_certificates_total;
  const canvasReportTimestamp =
    candidatesList[0]?.contest?.canvass_report_timestamp;

  const itemsCount = contestName !== "senatorial race" ? 7 : 11;
  const [showAll, setIsShowAll] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isReadMore, setIsReadMore] = useState(true);
  const [showChart, setShowChart] = useState(false);
  const [showCandidatesList, setShowCandidatesList] = useState(true);

  const fetcher = (url, body) =>
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body,
    }).then(async (res) => {
      return res.json();
    });

  // const { data: locationsData, error: locationsDataError } = useSWR(
  //   `/api/redis/snapshot-results?locationSlugs=philippines&raceType=${_raceType}&interval=${TIMELAPSE_INTERVAL_CHART}`,
  //   fetcher,
  //   {
  //    refreshInterval: 0,
  //     shouldRetryOnError: true,
  //     revalidateOnReconnect: true,
  //     refreshWhenOffline: true,
  //     errorRetryInterval: 5000,
  //     errorRetryCount: 1,
  //   }
  // );

  const { data: rawChartData, error: chartError } = useSWRImmutable(
    [
      `${cfUrl}/PHCumulativeVotes`,
      JSON.stringify({
        data: {
          raceType: _raceType,
        },
      }),
    ],
    fetcher,
    {
      refreshInterval: 0,
      shouldRetryOnError: true,
      revalidateOnReconnect: true,
      refreshWhenOffline: true,
      errorRetryInterval: 5000,
      errorRetryCount: 1,
    }
  );
  const chartData = rawChartData?.result;

  const { data: rawLatestTransmission, error: latestResultsPHError } =
    useSWRImmutable(
      [
        `${cfUrl}/latestTransmissionCount`,
        JSON.stringify({
          data: {
            locationSlug: _locationSlug,
          },
        }),
      ],
      fetcher,
      {
        refreshInterval: 0,
        shouldRetryOnError: true,
        revalidateOnReconnect: true,
        refreshWhenOffline: true,
        errorRetryInterval: 5000,
        errorRetryCount: 1,
      }
    );
  const latestResultsPHData = rawLatestTransmission?.result;

  const { data: rawTransmissionData, error: transmissionError } =
    useSWRImmutable(
      [
        `${cfUrl}/latestLocationResults`,
        JSON.stringify({
          data: {
            locationSlug: _locationSlug,
            raceType: _raceType,
            totalVoteCount: latestResultsPHData?.voteCount
              ? latestResultsPHData.voteCount
              : 0,
          },
        }),
      ],
      fetcher,
      {
        refreshInterval: 0,
        shouldRetryOnError: true,
        revalidateOnReconnect: true,
        refreshWhenOffline: true,
        errorRetryInterval: 5000,
        errorRetryCount: 1,
      }
    );
  const transmissionData = rawTransmissionData?.result;

  useEffect(() => {
    setIsMounted(true);
    if (!withCollapse) {
      setIsShowAll(true);
    }
  }, [withCollapse]);

  // Get candidates vote percentage and count && Null state handling
  candidatesList?.forEach((candidate) => {
    if (!transmissionData || transmissionData.length <= 0) {
      candidate.votePercentage = "0";
      candidate.voteCount = 0;
      return;
    }

    let item = transmissionData.find(
      (x) => x.candidateSlug.toLowerCase() === candidate.wp_slug.toLowerCase()
    );
    if (item === undefined || item === null) {
      candidate.votePercentage = "0";
      candidate.voteCount = 0;
    } else {
      candidate.votePercentage = item.votePercentage;
      candidate.voteCount = item.voteCount;
    }
  });

  // const getTimestamps = () => {
  //   const labels = [];
  //   if (chartData && chartData.length) {
  //     let endDate = addHours(49, new Date(chartData[0].timeMarker));
  //     if (chartData.length > 50)
  //       endDate = chartData[chartData.length - 1].timeMarker;
  //     for (
  //       let timeMarker = chartData[0].timeMarker;
  //       timeMarker <= endDate;
  //       timeMarker += TIMELAPSE_INTERVAL_CHART
  //     ) {
  //       labels.push(getFormattedDateTime(timeMarker));
  //     }
  //   }

  //   return labels;
  // };

  const cumulativeVotesData = () => {
    const labels = [];
    const datasets = [];

    if (chartData && chartData.length) {
      candidatesList.forEach((candidate) => {
        if (candidate.selected) {
          const data = [];
          const timestamps = [];
          const transmissionPercentage = [];
          // push 0 state
          // for testing purposes, added handling to use min timestamp if not yet actual transmission
          data.push({
            x:
              chartData[0].timestamp < ELECTION_DAY_TRANSMISSION_START
                ? new Date(chartData[0].timestamp - 15 * 60 * 1000)
                : new Date(ELECTION_DAY_TRANSMISSION_START),
            y: 0,
          });
          timestamps.push(
            getFormattedDateTime(
              chartData[0].timestamp < ELECTION_DAY_TRANSMISSION_START
                ? new Date(chartData[0].timestamp - 15 * 60 * 1000)
                : new Date(ELECTION_DAY_TRANSMISSION_START)
            )
          );
          transmissionPercentage.push("0");
          chartData.forEach((cumulativeData) => {
            const candidateData = cumulativeData.data.find(
              (item) =>
                item?.candidateSlug?.toLowerCase() ===
                candidate?.wp_slug?.toLowerCase()
            );
            if (candidateData) {
              data.push({
                x: new Date(cumulativeData.timestamp),
                y: candidateData.voteCount,
              });
              timestamps.push(getFormattedDateTime(cumulativeData.timestamp));
              transmissionPercentage.push(
                getTransmissionPercentage(
                  cumulativeData.precinctsTransmitted,
                  TOTAL_PRECINCTS_PH
                )
              );
            }
          });

          datasets.push({
            label: `${candidate.last_name}, ${
              candidate.alternate_name
                ? candidate.alternate_name
                : candidate.first_name
            }`,
            data,
            timestamps,
            rawName: candidate.raw_name,
            thumbnail: candidate.thumbnail,
            transmissionPercentage,
            borderColor: candidate.color,
            borderWidth: 1,
            backgroundColor: candidate.color,
            pointStyle: candidate.legend,
            pointBorderColor: candidate.color,
            pointBorderWidth: 2,
            pointRadius: 2,
            pointHoverRadius: 10,
          });
        }
      });
    } else {
      datasets.push({
        label: "",
        data: null,
      });
    }
    return {
      labels,
      datasets,
    };
  };

  const renderChart = () => {
    return (
      <>
        <div className={styles.chartsWrapper}>
          <CumulativeChart dataChart={cumulativeVotesData()} />
        </div>
        <div className={styles.chartsLegendWrapper}>
          <ChartLegend candidatesList={candidatesList} />
        </div>
      </>
    );
  };

  const renderDefaultCandidateList = () => {
    return (
      <div className={styles.electionRaceContainer_candidates}>
        <ol>
          {candidatesList &&
            candidatesList
              .slice(0, withCollapse ? 7 : candidatesList.length)
              .map((res) => (
                <Link
                  href={
                    urlPath ? `${urlPath}${res.wp_slug.toLowerCase()}` : url
                  }
                  key={res.id}
                  passHref
                >
                  <a target="_blank">
                    <li>
                      <div className={styles.candidate_container}>
                        <div className={styles.candidate}>
                          <Image
                            loader={loader}
                            src={
                              res?.thumbnail !== null
                                ? res?.thumbnail
                                : (res.gender || "").toLowerCase() === "female"
                                ? GenericFemaleThumbnail
                                : GenericMaleThumbnail
                            }
                            alt={`${res.raw_name}`}
                            layout="fixed"
                            width={70}
                            height={70}
                          />
                          <ul className={styles.details}>
                            <li className={styles.id}>
                              <h3 className={styles.name}>
                                {res.raw_name.split(/[,.]/)[0]}
                                <span
                                  data-text={`
                                ${res.raw_name
                                  .split(/[()]+/)[0]
                                  .trim()
                                  .split(/[,.]/)
                                  .slice(1, 3)
                                  .join()}
                                (${
                                  res.raw_name
                                    .split(/[()]+/)
                                    .filter(function (e) {
                                      return e;
                                    })[1]
                                })`}
                                />
                              </h3>
                            </li>
                          </ul>
                        </div>
                        <div className={styles.arrow}>
                          <Image
                            loader={loader}
                            src={RightArrow}
                            alt="right arrow"
                            layout="fixed"
                            width={20}
                            height={20}
                          />
                        </div>
                      </div>
                    </li>
                  </a>
                </Link>
              ))}
        </ol>
        {!showAll && (
          <div className={styles.btnViewAll}>
            <Link
              href={`${basePath}elections/2022/races/president-vice-president`}
              className={styles.button}
              onClick={() => {
                setIsShowAll(true);
              }}
              passHref
            >
              <a className={styles.view}>View All</a>
            </Link>
          </div>
        )}
      </div>
    );
  };

  const greenBars = (i) => {
    if (i === 0) return styles.greenBars;
  };

  const sumOfReturnTurnout = (arr) => {
    return arr.reduce((accumulator, object) => {
      return accumulator + object.result_turnout;
    }, 0);
  };

  // const sum = arr.reduce((accumulator, object) => {
  //   return accumulator + object.result_turnout;
  // }, 0);

  return (
    <div
      className={
        !resetContainerMargin
          ? `${styles.electionRaceContainer} ${marginTop}`
          : `${styles.electionRaceContainer} ${styles.resetContainer} ${marginTop}`
      }
    >
      <div className={styles.containerHeader}>
        <div className={styles.titleSubWrapper}>
          <div>
            <h2 className={styles.containerHeader_title}>{contestName}</h2>
            {subText && (
              <h5 className={styles.containerHeader_sub}>{subText}</h5>
            )}
          </div>
        </div>
        {view !== "official-results" ? (
          <p className={`${styles.containerHeader_description}`}>
            <b>
              {latestResultsPHData && latestResultsPHData.transmissionCount
                ? getTransmissionPercentage(
                    latestResultsPHData.transmissionCount,
                    _PRECINCTS
                  )
                : "0"}
              %
            </b>{" "}
            precincts reporting as of{" "}
            <b>
              {latestResultsPHData && latestResultsPHData.timestamp
                ? getFriendlyFormattedDateTime(latestResultsPHData.timestamp)
                : getFriendlyFormattedDateTime(new Date().getTime())}
            </b>
            . Unofficial-partial results based on real-time data from the
            Comelec transparency server.{" "}
            {["presidential race", "vice presidential race"].includes(
              contestName
            ) && isProvince
              ? "Votes for national positions at provincial level include those for highly urbanized and independent component cities situated in the province."
              : ""}
          </p>
        ) : canvasReportCurrent === canvasReportTotal ? (
          <p className={`${styles.containerHeader_description}`}>
            Final, official results based on <b>{canvasReportCurrent}</b>{" "}
            certificates of canvass.
          </p>
        ) : (
          <p className={`${styles.containerHeader_description}`}>
            Official results based on{" "}
            <b>
              {canvasReportCurrent} of {canvasReportTotal}
            </b>{" "}
            certificates of canvass as of <b>{canvasReportTimestamp}</b>.
          </p>
        )}
      </div>
      {showCandidatesList ? (
        <div className={`${styles.electionRaceContainer_candidates_results}`}>
          <div className={styles.titleContainer}>
            <div className={`${styles.resultsTitle} ${styles.initialTitle}`}>
              <h6>rank</h6>
              <h6>candidates</h6>
            </div>
            <div className={styles.resultsTitle}>
              <h6>votes</h6>
              <h6>percent</h6>
            </div>
          </div>
          <ol>
            {candidatesList &&
              candidatesList
                .sort((a, b) =>
                  view !== "official-results"
                    ? b.voteCount - a.voteCount
                    : a.ranking - b.ranking
                )
                .map((items, i) => ({
                  rank: i + 1,
                  ...items,
                }))
                ?.slice(0, withCollapse ? itemsCount : candidatesList.length)
                .map((result, i) => (
                  <Link
                    href={
                      urlPath
                        ? `${urlPath}${result.wp_slug.toLowerCase()}`
                        : url
                    }
                    key={result.id}
                    passHref
                  >
                    <a target={isEmbed ? "_blank" : ""}>
                      <li>
                        <div className={styles.candidate_container}>
                          <div className={styles.candidateDetails}>
                            <h4 className={styles.rank}>
                              {transmissionData && transmissionData.length
                                ? result.rank
                                : ""}
                            </h4>
                            <div className={styles.candidate}>
                              <Image
                                loader={loader}
                                src={
                                  result.thumbnail
                                    ? result.thumbnail
                                    : (result.gender || "").toLowerCase() ===
                                      "female"
                                    ? GenericFemaleThumbnail
                                    : GenericMaleThumbnail
                                }
                                alt={result.raw_name}
                                layout="fixed"
                                width={70}
                                height={70}
                              />
                            </div>
                            <div className={styles.detailsContainer}>
                              <div className={styles.detailsWrapper}>
                                <ul className={styles.details}>
                                  <h3 className={styles.name}>
                                    {result.raw_name?.split(/[,.]/)[0]}
                                    <span
                                      data-text={`
                                  ${result.raw_name
                                    ?.split(/[()]+/)[0]
                                    .trim()
                                    .split(/[,.]/)
                                    .slice(1, 3)
                                    .join()}
                                  (${
                                    result.raw_name
                                      ?.split(/[()]+/)
                                      .filter(function (e) {
                                        return e;
                                      })[1]
                                  })`}
                                    />
                                  </h3>
                                </ul>
                                <div className={styles.votesDetails}>
                                  {view !== "official-results" ? (
                                    <p className={styles.votes}>
                                      {result?.voteCount?.toLocaleString()}{" "}
                                    </p>
                                  ) : (
                                    <p className={styles.votes}>
                                      {result?.result_turnout?.toLocaleString()}{" "}
                                    </p>
                                  )}
                                  {view !== "official-results" ? (
                                    <p className={styles.percent}>
                                      {result?.votePercentage}%
                                    </p>
                                  ) : (
                                    <p className={styles.percent}>
                                      {getTransmissionPercentage(
                                        result?.result_turnout,
                                        sumOfReturnTurnout(candidatesList)
                                      )}
                                      %
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className={styles.progressDesktop}>
                                {view !== "official-results" ? (
                                  <ProgressBarWidget
                                    color={styles.test}
                                    percentage={result?.votePercentage}
                                  />
                                ) : (
                                  <ProgressBarWidget
                                    color={greenBars(i)}
                                    percentage={getTransmissionPercentage(
                                      result?.result_turnout,
                                      sumOfReturnTurnout(candidatesList)
                                    )}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className={styles.progressMobile}>
                            {view !== "official-results" ? (
                              <ProgressBarWidget
                                color={styles.test}
                                percentage={result?.votePercentage}
                              />
                            ) : (
                              <ProgressBarWidget
                                color={greenBars(i)}
                                percentage={getTransmissionPercentage(
                                  result?.result_turnout,
                                  sumOfReturnTurnout(candidatesList)
                                )}
                              />
                            )}
                          </div>
                        </div>
                      </li>
                    </a>
                  </Link>
                ))}
          </ol>
          {!showAll && (
            <div className={styles.btnViewAll}>
              <Link
                href={url}
                className={styles.button}
                onClick={() => {
                  setIsShowAll(true);
                }}
                passHref
              >
                <a className={styles.view}>View All</a>
              </Link>
            </div>
          )}
        </div>
      ) : showChart ? (
        <>{renderChart()}</>
      ) : null}

      {view !== "default" && withResultsFooter && (
        <div className={`${styles.resultsFooter}  ${display}`}>
          {view !== "overseas-absentee-voting-results" && (
            <h6>*Already includes Overseas Absentee Votes</h6>
          )}
        </div>
      )}
      <div className={styles.buttonsWrapper}>
        {view !== "official-results" && (
          <div className={`${styles.leftButtons} ${display}`}>
            {showCandidatesList ? (
              <button
                onClick={() => {
                  setShowCandidatesList(false);
                  setShowChart(true);
                }}
              >
                <Image
                  loader={loader}
                  src={RewindIcon}
                  alt="rewind icon"
                  width={25}
                  height={25}
                />
                <p>rewind</p>
              </button>
            ) : showChart ? (
              <button
                onClick={() => {
                  setShowChart(false);
                  setShowCandidatesList(true);
                }}
              >
                <Image
                  loader={loader}
                  src={BackArrow}
                  alt="back arrow"
                  width={20}
                  height={20}
                />
                <p>BACK</p>
              </button>
            ) : null}

            <Link
              href={
                basePath +
                "elections/2022/races/president-vice-president/map-results"
              }
              passHref
            >
              <a className={styles.label}>
                <Image
                  loader={loader}
                  src={PinIcon}
                  alt="map icon"
                  width={25}
                  height={25}
                />
                <p>MAP</p>
              </a>
            </Link>
          </div>
        )}

        <WidgetShare
          loader={loader}
          url={url}
          fbQuote={quote}
          tWQuoute={quote}
          toolTipId={`toolTip_Default_${transformRaceName(raceName)}`}
          isMounted={isMounted}
          isEmbedShowed={!isEmbed && locSlug === "philippines"}
          embedUrl={`${basePath}elections/2022/races/embed/${view}/${transformRaceName(
            raceName
          )}`}
        />
      </div>
    </div>
  );
};

export default RaceWidget;
