import styles from "../../../styles/races/national/MapResults.module.scss";

const ChartLegend = ({ candidatesList }) => {

  return (
    <div className={styles.legendContainer}>
      {
        candidatesList.map((candidate, index) => {
          return (
            <div key={index}>
              <div className={styles[[candidate.legend]]} style={{ backgroundColor: candidate.legend !== "triangle" ? candidate.color : "transparent", borderBottom: candidate.legend === "triangle" ? `10px solid ${candidate.color}`: "none" }}></div><span>{`${candidate.last_name}, ${candidate.alternate_name ? candidate.alternate_name : candidate.first_name}`}</span>
            </div>
          )
        })
      }
    </div>
  );
}

export default ChartLegend;
